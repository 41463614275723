import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import screenshot1 from "../images/screen1.jpg";
import screenshot2 from "../images/screen2.jpg";
import screenshot3 from "../images/screen3.jpg";
import screenshot4 from "../images/screen4.jpg";
import RunTools from "../tools";

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    RunTools();
  }, []);

  return (
    <div>
      <div className="jumbotron jumbotron-frontpage">
        <div className="container">
          <div className="row">
            <div className="col col-sm-6">
              <h1>{t("home.top-title.title")}</h1>
              <h2>
                {t("home.top-title.text1")}
                <br className="visible-xs-inline" />
                {t("home.top-title.text2")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="container-info">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1>{t("home.content-title")}</h1>
          </div>
          <div className="col-sm-10 col-sm-offset-1 text-center">
            <p className="lead">{t("home.content-title-description")}</p>
          </div>

          <div className="bullets">
            <div className="col-sm-4 text-center bullet">
              <i className="fa fa-magic hidden-xs bullet-icon-large"></i>
              <p><strong><i className="fa fa-magic visible-xs-inline"></i> {t("home.content-item1-title")} </strong></p>
              <p>{t("home.content-item1-description")}</p>
            </div>
            <div className="col-sm-4 text-center bullet">
              <i className="fa fa-calendar-check-o hidden-xs bullet-icon-large"></i>
              <p><strong><i className="fa fa-calendar-check-o visible-xs-inline"></i> {t("home.content-item2-title")}</strong></p>
              <p>{t("home.content-item2-description")}</p>
            </div>
            <div className="col-sm-4 text-center bullet">
              <i className="fa fa-paper-plane-o hidden-xs bullet-icon-large"></i>
              <p><strong><i className="fa fa-paper-plane-o visible-xs-inline"></i> {t("home.content-item3-title")}</strong></p>
              <p>{t("home.content-item3-description")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="container-about">
        <div className="row">
          <div className="col-sm-6">
            <div className="gallery">
              <div className="row">
                <div className="col-xs-6 col-md-3 col-md-offset-3">
                  <a href={screenshot1}>
                    <img src={screenshot1} className="img-responsive" alt=''/>
                  </a>
                </div>
                <div className="col-xs-6 col-md-3">
                  <a href={screenshot2}>
                    <img src={screenshot2}  className="img-responsive" alt=''/></a>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6 col-md-3 col-md-offset-3">
                  <a href={screenshot3}>
                    <img src={screenshot3}  className="img-responsive" alt=''/>
                  </a>
                </div>
                <div className="col-xs-6 col-md-3">
                  <a href={screenshot4}>
                    <img src={screenshot4}  className="img-responsive" alt=''/>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="tabs">
              <ul className="tab-triggers">
                <li className="tab-trigger active"><a href="#about" data-target="about">{t("buttons.about")}</a></li>
                <li className="tab-trigger"><a href="#background" data-target="background">{t("buttons.background")}</a></li>
              </ul>
              <div className="tab-target tab-target-active tab-target-about">
                <p>{t("home.content-about.0")}</p>
                <p>{t("home.content-about.1")}</p>

              </div>
              <div className="tab-target tab-target-background" style={{display: 'none'}}>
                <p>{t("home.content-background.row1")}</p>
                <p>{t("home.content-background.row2")}</p>
                <p>{t("home.content-background.row3")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
