import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };

  return (
    <select className=" language-select" onChange={changeLanguage}>
      <option value="en">en</option>
      <option value="sv">sv</option>
    </select>
  );
}

export default LanguageSwitcher;
